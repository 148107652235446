import { gql } from "@apollo/client"
import { checkoutProductVariantFragment } from "@temp/@next/globalStates/Checkout/queries"

export const getLastPurchasedOrdersQuery = gql`
  ${checkoutProductVariantFragment}
  query getLastPurchased {
    me {
      id
      lastYearOrders {
        id
        created {
          value
        }
        fulfillments {
          id
          lines {
            id
            orderLine {
              id
              productName
              quantity
              quantityFulfilled
              variant {
                ...ProductVariant
              }
            }
          }
        }
        shipments {
          id
          awb
          fulfillments {
            id
            lines {
              id
              orderLine {
                id
                productName
                quantity
                quantityFulfilled
                variant {
                  ...ProductVariant
                }
              }
            }
          }
          status
          deliveryReview {
            id
            rating
            review
          }
          deliveryStatus
        }
        lines {
          id
          productName
          variation {
            id
          }
          quantityFulfilled
          variant {
            id
            name
            product {
              id
              name
              brand {
                id
                name
              }
            }
          }
          unitPrice {
            gross {
              amount
            }
          }
          firstImage {
            id
            url
          }
        }
      }
    }
  }
`
export const getLastPurchasedReviewsQuery = gql`
  query getLastPurchasedReviews($productIds: [ID]) {
    me {
      id
      productReviews(productIds: $productIds, first: 10) {
        edges {
          node {
            id
            review
            recommends
            product {
              id
            }
            unboxingMedias(first: 1) {
              edges {
                node {
                  id
                  availabilityStatus
                }
              }
            }
          }
        }
      }
    }
  }
`
